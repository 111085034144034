<template>
  <div id="MonthlyChange" class="Content-Page">
    <div id="namePage" class="wrap-Main">
      <div class="box-S4">
        <div class="N-Page T-size-36">Monthly Change</div>
      </div>
    </div>
    <div id="selectMonthOwner" class="wrap-Main">
      <div class="box-S4">
        <div class="N-Page T-size-24">Select Data From</div>
      </div>
      <div class="box-S2">
        <div class="B-carddetail flex-between">
          <div class="box-S2 noPadding">
            <v-select
              :items="itemsListCustomer"
              v-model="selectcustomer"
              item-text="o_name"
              item-value="o_id"
              label="Customer"
              return-object
              dense
              hide-details
            ></v-select>
          </div>
          <div class="box-S2 noPadding">
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              :nudge-right="0"
              :nudge-top="0"
              transition="scale-transition"
              offset-y
              hide-details
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  class="hideMessage input-date"
                  append-icon="mdi-calendar-range"
                  v-model="picker"
                  label="Select Month"
                  readonly
                  dense
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="picker"
                type="month"
                @change="getData(picker)"
                @input="menu = false"
              ></v-date-picker>
            </v-menu>
          </div>
        </div>
      </div>
      <div class="line marginTop"></div>
    </div>

    <div id="ReportData" class="wrap-Main">
      <div class="box-S4">
        <div class="N-Page T-size-24">Report</div>
      </div>
      <div class="box-S4" v-for="item in arrAircraft" :key="item">
        <div class="B-carddetail">
          <div class="box-S4 noPadding">
            <div class="N-Page T-size-20">{{item.ac_name}}</div>
          </div>
          <div class="box-S4 flex-between noPadding">
            <div class="box-S1 noPadding">
              <v-select
              :items="itemsListStatus"
                label="Status"
                dense
                hide-details
                item-text="value"
              item-value="key"
                v-model="item.status"
                return-object
              ></v-select>
            </div>
            <div class="box-S1 noPadding">
              <v-select
              :items="itemsListProject"
                label="Project"
                dense
                hide-details
                item-text="sp_name"
              item-value="sp_code"
                v-model="item.project"
                return-object
              ></v-select>
            </div>
            <div class="box-S1 noPadding">
              <v-select
              :items="itemsListDepartment"
                label="Department"
                dense
                hide-details
                item-text="scd_name"
              item-value="scd_code"
                v-model="item.department"
                return-object
              ></v-select>
            </div>
            <div class="box-S1 noPadding">
              <v-select
              :items="itemsListBOI"
                label="BOI"
                dense
                hide-details
                item-text="scb_name"
              item-value="scb_code"
                v-model="item.boi"
                return-object
              ></v-select>
            </div>

          </div>
        </div>
      </div>
      <div class="line marginTop"></div>
    </div>

    <div id="partSave" class="wrap-Main">
      <div class="box-S4">
        <div class="b-btnSend">
          <v-btn class="theme-btn-even btn-sendSap Bsize150" @click="SaveList()">
            <span class="T-size-18">Send to SAP</span>
          </v-btn>
        </div>
      </div>
    </div>
    <div id="partBack" class="wrap-Main">
      <div class="line"></div>
      <div class="box-S4">
        <v-btn text class="ColorTheme-S white--text theme-btn" @click="Back()">
          <span class="I-back"></span>
          <span>Back</span>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { startOfDay, endOfDay, format } from "date-fns";
import feathersClientUOA from "../plugins/feathers-client-uoa";
import feathersClientSAP from "../plugins/feathers-client-sap";
import { onEmpty } from '@syncfusion/ej2-vue-grids';

export default {
  data: () => ({
    // selectcompany:'',
    // itemsListCompany: [],
    //Edit
    selectcustomer:'',
    itemsListCustomer: [],

    arrAircraft:[],
    itemsListProject: [],
    itemsListDepartment:[],
    itemsListStatus:[{key:"-",value:"Not Available",itemname:"-"},
    {key:"Rev-001",value:"Available",itemname:"Revenue - Fixed Monthly Standing Charge"},
    {key:"Rev-006",value:"Suspense",itemname:"Revenue Suspense - Fixed Monthly Standing Charge"}],
    itemsListBOI:[],
    menu: false,
    picker: new Date().toISOString().substr(0,7)

}),
  async mounted() {
    //this.userdata = JSON.parse(localStorage.getItem("user"));.
    //Edit
    this.userdata = await this.$CurrentUser.GetCurrentUser();
    // this.SelectListCompany();
    //Edit
    await this.SelectListCustomer();
    this.ListAircraft();
    this.SelectListProject();
    this.SelectListDepartment();
    this.SelectListBOI();
     
  },
  methods: {
    Back() {
      this.$router.go(-1);
    },
    async SelectListCustomer() {
      try {
        var q = {};
        var res = await feathersClientUOA.service("customer").find({query:q});
        this.itemsListCustomer = res.data;
        this.selectcustomer = { o_id: this.itemsListCustomer[0].o_id, o_name:this.itemsListCustomer.o_name}
        
      } catch (error) {
        console.log(error);
        alert("ไม่สามารถขอข้อมูลจาก server ได้");
      }
    },
    async ListAircraft() {
      try {
        var res = await feathersClientUOA.service("aircrafts").find({});
        this.arrAircraft = []
        res.data.forEach(element => {
          var arritem = {}
          arritem['ac_name'] = element.ac_name
          arritem['status'] = "-"
          arritem['project'] = ''
          arritem['department'] = ''
          arritem['boi'] = ''
          this.arrAircraft.push(arritem)
        });
        //this.arrAircraft = res.data;
      } catch (error) {
        console.log(error);
        alert("ไม่สามารถขอข้อมูลจาก server ได้");
      }
      
    },
    async SelectListProject() {
      try {
        var res = await feathersClientUOA.service("sapproject").find({});

        this.itemsListProject = res.data;
      } catch (error) {
        console.log(error);
        alert("ไม่สามารถขอข้อมูลจาก server ได้");
      }
    },
    async SelectListDepartment() {
      try {
        var res = await feathersClientUOA.service("sapcogsdepartment").find({});

        this.itemsListDepartment = res.data;
      } catch (error) {
        console.log(error);
        alert("ไม่สามารถขอข้อมูลจาก server ได้");
      }
    },
    async SelectListBOI() {
      try {
        var res = await feathersClientUOA.service("sapcogsboi").find({});

        this.itemsListBOI = res.data;
      } catch (error) {
        console.log(error);
        alert("ไม่สามารถขอข้อมูลจาก server ได้");
      }
    },
    async SaveList(){

      let DocDate = new Date().toISOString().split("T", 1).toString().replace("-", "").replace("-", "");
      var DataInsertHead = {
            Series: 'M',
            DocDate: DocDate,
            // CardCode:this.selectcompany.c_sap_customer_code,
            // CardName:this.selectcompany.c_sap_customer_name,
            //Edit
            CardCode:this.selectcustomer.c_sap_customer_code,
            CardName:this.selectcompany.c_sap_customer_name,
            CardContact:null,
            Remark:null,
            FlightOperation:this.userdata.FullName,
            CreateDate:DocDate,
            SOInternalKey:null,
            INFStatus:0
          }
          this.InsertHead(DataInsertHead);
    },
    async InsertHead(DataInsertHead) {
      try {
       var res = await feathersClientUOA.service("sapinfsohead").create(DataInsertHead);

       this.GenLine(res.soh_id);
      } catch (error) {
        console.log(error);
      }

      try {
       var res = await feathersClientSAP.service("infsohead").create(DataInsertHead);
       console.log(res.ID)

       this.GenLineSAP(res.ID);
      } catch (error) {
        console.log(error);
      }
    },
    async GenLine(idHead) {
       var i = 0;
      this.arrAircraft.forEach(element => {
         if(element.status !== '-'  ){
           if(element.status.key !== '-'){
            i++;
            var DataInsertLine = {
              soh_id: idHead,
              LineNo: i,
              Itemcode:element.status.key,
              ItemName:element.status.itemname,
              Quantity:1,
              Project:element.project.sp_code,
              Ocrcode:element.department.scd_code,
              Ocrcode2:element.boi.scb_code,
              Minute:0
            }
          this.InsertLine(DataInsertLine);
           }
         }
      });
    },
     async GenLineSAP(idHead) {
      // console.log("-----" + idHead)
       var i = 0;
      this.arrAircraft.forEach(element => {

 
         if(element.status !== '-'  ){

           if(element.status.key !== '-'){
            i++;
            var DataInsertLine = {
              HeadID: idHead,
              LineNo: i,
              Itemcode:element.status.key,
              ItemName:element.status.itemname,
              Quantity:1,
              Project:element.project.sp_code,
              Ocrcode:element.department.scd_code,
              Ocrcode2:element.boi.scb_code,
              Minute:0
            }
            this.InsertLineSAP(DataInsertLine);
           }
          
         }
      });
    },
    async InsertLine(DataInsertLine) {
      try {
       var res = await feathersClientUOA.service("sapinfsoline").create(DataInsertLine);
      } catch (error) {
        console.log(error);
      }
    },
     async InsertLineSAP(DataInsertLine) {

      try {
       var res = await feathersClientSAP.service("infsoline").create(DataInsertLine);
      } catch (error) {
        console.log(error);
      }
    },
  }
};
</script>
<style>
</style>